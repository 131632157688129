import { API } from "@project/shared";

export const addClub = async (formData): Promise<any> => {
  return API.post("/clubs", formData);
};

export const getClubs = async () => {
  const response = await API.get("/clubs");
  if (response) {
    return response.data;
  }
  // return API.get("/clubs");
};

export const getClub = async (id: string | string[]) => {
  const { data } = await API.get(`/clubs/${id}`);
  return data;
};

export const updateClub = async ({ data }) => {
  return API.put(`clubs/${data.id}`, data);
};
