import { PageHeader as AntdPageHeader } from "antd";
import styled from "styled-components";
import { Button, theme } from "@project/shared";
import { useRouter } from "next/router";

interface Props {
  href?: string;
  title: string;
  noBack?: boolean;
  withButton?: boolean;
  action?: string;
  icon?: any;
  actionHref?: string;
}

const PageHeader = styled(AntdPageHeader)`
  background: ${theme.white};
  z-index: 1000;
  position: sticky;
  top: 0px;
  height: 54px;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.gray5};
  padding: 18px 24px;
  & svg {
    fill: ${theme.gray9};
  }
  & .ant-page-header-heading {
    width: 100%;
  }
  & .ant-page-header-heading-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: ${theme.gray9};
  }
  & .ant-page-header-heading-extra {
    margin-left: auto;
  }
  & button {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    & svg {
      font-size: 12px;
    }
  }
`;

const PageHeaderComponent = ({
  href,
  title,
  noBack,
  withButton,
  actionHref,
  action,
  icon,
}: Props) => {
  const router = useRouter();
  return (
    <PageHeader
      onBack={noBack ? null : () => router.push(href || "/")}
      title={title}
      extra={
        withButton && (
          <Button
            type="primary"
            onClick={() => router.push(href || "/club/add")}
            href={actionHref}
            icon={icon}
          >
            {action}
          </Button>
        )
      }
    />
  );
};

export { PageHeaderComponent };
